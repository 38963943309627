<template>
  <div class="content">
    <div id="product-title" class="product-title">
      <img src="./../assets/message.png" alt="" />
      <div class="banner-text">
        {{ word.foot.company.TermsService[language] }}
      </div>
    </div>

    <h1 class="terms-hdng">LEGAL TERMS OF SERVICE</h1>
    <h2 class="terms-list-hdng">YONI-TECH TERMS OF SERVICE</h2>
    <p class="terms-desc">
      Welcome, and thank you for your interest in the online services
      collectively known as YONI-TECH. These Terms of Service are a legally
      binding contract between you and YONI-TECH regarding your use of the
      Services. For the purposes of these Terms of Service,"YONI-TECH," "We,"
      "Our," and "Us" refer to the applicable YONI-TECH contracting entity.
    </p>
    <p class="terms-desc">PLEASE READ THE FOLLOWING TERMS CAREFULLY.</p>
    <p class="terms-desc">
      It is important that You understand Your responsibilities and the
      limitations to the services which You choose to use. Our services are
      diverse and as a result, additional service-specific terms may apply. If
      this is the case, these service-specific terms shall become part of Our
      agreement.
    </p>
    <p class="terms-desc">
      Please use Our services responsibly. By using Our website or any of Our
      services, You agree to Our Terms. Take note that these Terms change from
      time to time. We advise You to review them regularly.
    </p>

    <h2 class="terms-list-hdng">1. Introduction</h2>
    <p class="terms-desc">
      1.You wish to use YONI-TECH services (meaning services as provided under
      these Terms; hereinafter "Service" or "Free Service" collectively the
      "Services") as either a paying customer ("Customer") and/or Our Free
      Service as a non-paying customer ("Non-Paying Customer"). After applying
      for the services by Our online registration form, You will become Our
      Non-Paying Customer according to Clause 4.1 until decided otherwise on
      YONI-TECH's sole discretion.
    </p>

    <p class="terms-desc">
      2.You wish to use the Services and We are willing to make the Services
      available to You subject to and in accordance with these Terms.
    </p>

    <h2 class="terms-list-hdng">2. Binding Effect</h2>
    <p class="terms-desc">
      1.You are entering into a binding agreement. If You accept these Terms of
      use of Our Service on behalf of Your employer or another person, You
      confirm that You have the consent of Your employer or that person to act
      on their behalf. THESE TERMS APPLY TO ALL RECORDS RELATING TO ALL
      TRANSACTIONS YOU ENTER INTO ON YONI-TECH'S WEBSITE, INCLUDING NOTICES OF
      CANCELLATION, POLICIES, CONTRACTS, AND APPLICATIONS. In order to access
      and retain your electronic records, You may be required to have certain
      hardware and software, which are Your sole responsibility.
    </p>
    <p class="terms-desc">
      2.You are not eligible to accept these Terms or use Our Services if You
      are not of legal age to form a binding contract with Us or if You are
      barred by law to use Our services.
    </p>

    <h2 class="terms-list-hdng">3. Accounts</h2>
    <h3 class="terms-list-hdng-h3">1.Registration.</h3>
    <p class="terms-desc-small">
      a.In order to provide Our services, We may require certain information
      such as your name, company name, email address. You must ensure that the
      information is complete and accurate. We may suspend or terminate any
      service if You give information that is not complete and accurate, and You
      indemnify YONI-TECH against any liability that may arise as a result of
      Your failure to provide complete and accurate information. You must
      immediately notify Us if any of Your information changes.
    </p>
    <p class="terms-desc-small">
      b.We will give You or ask You to choose a user name or customer ID and
      password for each account.
    </p>

    <h3 class="terms-list-hdng-h3">
      2.Accounts Security. For accounts security You:
    </h3>
    <p class="terms-desc-small">
      a.must keep Your username, customer ID and password confidential;
    </p>
    <p class="terms-desc-small">
      b.must not circumvent, or attempt to circumvent Our user authentication
      systems;
    </p>
    <p class="terms-desc-small">
      c.must inform Us immediately if there is any unauthorized use of Your
      account or any other breach of security;
    </p>
    <p class="terms-desc-small">
      d.are entirely responsible for all payments and any activities that occur
      on Your account;
    </p>
    <p class="terms-desc-small">
      e.are liable for any damage, loss or costs that we or any third party may
      sustain as a result of any of your actions, or any actions of a third
      party using Your password, account name or account information; and
    </p>
    <p class="terms-desc-small">
      f.must indemnify Us against any claim from any use of Your password,
      account name or account information by a third party or as a result of
      Your violation of this section.
    </p>
    <h3 class="terms-list-hdng-h3">
      3.You are solely responsible for making sure your Account comply with
      these Terms. YONI-TECH reserves the right to review all services processes
      to ensure your compliance with these Terms.
    </h3>

    <h2 class="terms-list-hdng">4. Paid Service and Free Service</h2>
    <p class="terms-desc">
      1.The Service enables a Paying Customer who official top-up to use Our
      mobile services as set out as part of Your online registration. Any such
      billable unit under the Services shall be referred in these Terms and
      Conditions as a “Chargeable Event”.
    </p>
    <p class="terms-desc">
      2.The Free Service will enable Non-Paying Customers to:
    </p>
    <p class="terms-desc-small">
      a.Use the Trial for one or more elements of the Service described in the
      above clause 4.1; and/or
    </p>
    <p class="terms-desc-small">
      b.Use any element of the Service described in the above clause 4.1 or any
      other service made available by Us to You free of charge from time to time
      at Our absolute discretion.
    </p>
    <p class="terms-desc">
      3.Some or all elements of the Free Service may be accessed via a web
      application, an API gateway, a software tool and/or a web service more
      particularly described on our website at www.yoni-tech.com
    </p>
    <p class="terms-desc">4.End of Free Service</p>
    <p class="terms-desc-small">
      a.After the Free Service session is completed, You will have the option to
      request the full Service which will enable You to become a Paying
      Customer.
    </p>
    <p class="terms-desc-small">
      b.Terms and conditions of the Service will be detailed in written form. In
      the event of any discrepancy between the Service terms stipulated
      hereunder and subsequent terms, the latter shall prevail.
    </p>
    <p class="terms-desc-small">
      c.No refunds: You understand that after you become a Paying customer, the
      refund you initiate does not include we test fee, the handling fee of the
      third-party payment system, and if you seriously violate the YC platform
      regulations, your recharge fee will not be refunded.
    </p>
    <p class="terms-desc-small">
      d.You understand that if you need to refund the top-up fees, the related
      handling fees will be charged by you, and you also understand that since
      intermediary banks have different rules for deducting fees, if your amount
      is too small, there may be no fee refund after deducting the handling
      fees, which are all charged by third parties, and we will not charge any
      fees.
    </p>
    <p class="terms-desc">
      5.Restrictions. You will not, and will not allow your Affiliates,
      employees, contractors and any third parties under your control,
      management, supervision, or otherwise to access the Services in a manner
      intended to avoid incurring Fees or otherwise avoid usage limitations.
    </p>
    <h2 class="terms-list-hdng">
      5. Availability and Interruption of the Service
    </h2>
    <p class="terms-desc">
      1.We will make the Services available to You as either a Customer and/or a
      Non-Paying Customer with reasonable skill and care. You do however
      acknowledge and agree that the availability of the Services, Your ability
      to access and/or use the Services and the conducting of any given
      Chargeable Event may depend upon factors beyond Our reasonable control,
      including but not limited to:
    </p>
    <p class="terms-desc-small">
      a.Factors affecting the operation of the Services and/or preventing
      Chargeable Event from being successfully conducted such as, by way of
      example, geographical or topographical shortcomings in the network of any
      telecommunications network operator (“Network Operator”), network
      capacity, physical obstructions or atmospheric conditions; or
    </p>
    <p class="terms-desc-small">
      b.Factors preventing end-users from receiving Chargeable Events such as,
      by way of example, the terms and conditions of an end-user’s service
      provider.
    </p>

    <p class="terms-desc">2.We cannot therefore guarantee:</p>
    <p class="terms-desc-small">
      a.That the Services will be available to You at all times or free from
      faults or interruptions;
    </p>
    <p class="terms-desc-small">
      b.The receipt by any intended recipient of any Chargeable Event sent using
      the Services (as applicable).
    </p>
    <p class="terms-desc">
      3.We will not be in any way liable for any failure to make the Services
      available to You to the extent that such failure results from a technical
      or another failure on the part of any Network Operator or any other event
      which is beyond Our reasonable control. We provide all services “as is”
      and “as available”, and We hereby do not warrant, represent or guarantee,
      whether expressly or by implication, that any Services are free of errors
      or interruptions, always available, fit for any purpose, secure or do not
      infringe any third party rights.
    </p>
    <p class="terms-desc">
      4.We may, at Our sole discretion, alter or improve the Services We provide
      to You at any time, provided that any such alteration does not materially
      affect the nature or detract from the functionality of the Services.
    </p>
    <p class="terms-desc">
      5.It may be necessary from time to time for Us to suspend the Services
      that We provide to You for routine or emergency maintenance and/or repairs
      and We will, in so far as it is reasonably possible, provide You with a
      reasonable period of notice prior to any such suspension.
    </p>
    <p class="terms-desc-small">
      a.We may at Our sole discretion suspend Your access to the Services and/or
      cease to allow any Chargeable Events to be conducted by You at any time.
      We are entitled to terminate these Terms for any reason, in which case We
      will give You appropriate notice as soon as is reasonably possible.
    </p>
    <p class="terms-desc-small">
      b.Should Your account utilized in the provision of YONI-TECH Service be
      inactive through a period of 12 (Twelve) months it remains in YONI-TECH’s
      discretion to suspend YONI-TECH Services to You. You can reactivate the
      account with a request to YONI-TECH sent 3 (three) working days prior to
      the required reactivation.
    </p>
    <p class="terms-desc">
      6.If YONI-TECH discontinues or makes any changes to the services that
      would materially decrease the functionality of those Services, YONI-TECH
      will use commercially reasonable efforts to inform you of the change with
      reasonable advance notice before it goes into effect, provided that you
      have subscribed to be informed about those changes. YONI-TECH may make the
      change, and will not be obligated to provide notice if the discontinuation
      or change is necessary to address an emergency or threat to the security
      or integrity of the services, comply with or respond to litigation,
      address Intellectual Property Rights concerns, or comply with the law or
      government requests. YONI-TECH may provide periodic updates to the
      services provided by YONI-TECH from time to time.
    </p>
    <p class="terms-desc">
      7.You agree and acknowledge that You/Your targeted user may not receive
      SMS messages if the content of Your/Your targeted user's SMS messages
      violates laws and regulations, violates this Agreement or if Your targeted
      user is on the anti-harassment list.
    </p>

    <h2 class="terms-list-hdng">6. Fees and Payments</h2>
    <p class="terms-desc">
      1.The provisions of this clause apply to Paying Customers only.
    </p>
    <p class="terms-desc">
      2.You agree to pay all Charges due to Us in respect of making the Service
      available to You and Your access to and use of the Service (“Customer
      Charges”) by the prepayment method and in accordance with the terms as set
      hereof.
    </p>
    <p class="terms-desc">
      3.Any Chargeable Event credits purchased by You are only valid for the
      period in respect of which they have been allocated, any additional
      expenditure of Chargeable Events, over Your allocation limit, in such
      period, shall not be possible. However, You will still be allowed to
      purchase new Chargeable Event credits and expand your allocation for the
      given period at any time.
    </p>
    <p class="terms-desc">
      4.You shall be solely responsible, by seeking adequate Chargeable Event
      credit allocation(s) and checking Your remaining available Chargeable
      Event credit level on Our website at www.yoni-tech.com, for ensuring that
      You have enough Chargeable Event credits to meet Your requirements from
      time to time and We shall not be in any way responsible or liable in the
      event that You have insufficient Chargeable Event credits to meet Your
      requirements, and/or have exceeded Your Chargeable Event credit
      allocation(s), for any period.
    </p>
    <p class="terms-desc">
      5.For the avoidance of doubt, a Charge will be incurred for every
      Chargeable Event conducted by You regardless of whether it is successfully
      delivered.
    </p>
    <p class="terms-desc">
      6.If You do not pay any Customer Charges in accordance with the applicable
      payment terms, We reserve the right to, in Our sole discretion, suspend
      Your access to the Service and/or cease to allow any Chargeable Event to
      be conducted by You until further payment is received by Us which fully
      covers any unpaid Charges.
    </p>
    <p class="terms-desc">
      7.You are responsible for the payment of all bank and finance charges.
      Please ensure that the amount received on Our bank account, after
      deductions, corresponds to the full amount you owe Us.
    </p>
    <p class="terms-desc">
      8.If any payment made via your chosen Payment Method is rejected, denied,
      not received by us or returned unpaid for any reason:
    </p>
    <p class="terms-desc-small">
      a.we may suspend or terminate your access to the Services until your
      payment is properly processed;
    </p>
    <p class="terms-desc-small">
      b.charges will continue to be incurred and you are liable to us for any
      fees, costs, expenses or other amounts we incur arising from such
      rejection, denial or return (and we may charge you for such amounts);
    </p>

    <p class="terms-desc">
      9.During the terms period, the price of international SMS/Voice under this
      agreement needs to be changed due to the adjustment of SMS/Voice price by
      telecom operators and telecom authorities. YONI-TECH shall adjust the
      price accordingly. Your continued use indicates acceptance of this price.
    </p>
    <p class="terms-desc">
      10.You will not be able to receive any refund for the payment made (“No
      refund, exchange only”). The latter shall not prevent any refund to be
      made according to the applicable customer protection laws.
    </p>
    <p class="terms-desc">11.Invoice</p>
    <p class="terms-desc">
      After payment, customers can follow the website operation to log in to the
      customer dashboard for invoice download.
    </p>
    <h2 class="terms-list-hdng">7. Confidentiality and Data Protection</h2>
    <p class="terms-desc">
      1.You will at all times keep confidential all information acquired as a
      consequence of using Our Services, except for information already in the
      public domain or information which You are required to disclose by law,
      requested by any Regulator or reasonably required by Your professional
      advisors for the performance of their professional services.
    </p>
    <p class="terms-desc">
      2.Please refer to Our Privacy Policy for details on Our privacy practices
      with respect to Our Services.
    </p>
    <p class="terms-desc">
      3.With respect to the processing of personal data of your end-users that
      you provide to us through our Services, You are the controller and
      YONI-TECH is the processor. You guarantee that you have obtained all
      required and valid consents under the applicable data protection laws and
      regulations (such as the EU General Data Protection Regulation) as
      required for the processing of personal data by YONI-TECH for the
      performance of our Services and YONI-TECH will process that personal data
      only upon Your instructions and in accordance with data processing
      agreement. YONI-TECH Service shall be made available subject to an
      executed Data Processing Agreement between you and YONI-TECH. If you wish
      to enter into a data processing agreement with YONI-TECH, you can send a
      request to service@yoni-tech.com and we will provide you with a pre-signed
      version of our Data Processing Agreement.
    </p>

    <h2 class="terms-list-hdng">8. Technical support</h2>
    <p class="terms-desc">
      Unless We agree otherwise in writing, We will provide online technical
      support in respect of the Service and/or Free Service available to You
      24/7 at the following e-mail address: service@yoni-tech.com
    </p>
    <h2 class="terms-list-hdng">9. Marketing</h2>
    <p class="terms-desc">
      You grant us the right to use your name and visual identity for publicity
      and marketing purposes without prior consent. Should this be unacceptable
      to you, the withdrawal of consent must be communicated in writing. Without
      limitation to the foregoing, no press release and/or any blog entry may be
      made by either party regarding this Agreement, without the prior consent
      of the other party.
    </p>
    <h2 class="terms-list-hdng">10. Requirements for Use</h2>
    <p class="terms-desc">1.You warrant that You will not:</p>
    <p class="terms-desc-small">
      a.Use the Services or permit the Services to be used to send Chargeable
      Events to any end-user for marketing purposes without that end-user’s
      explicit request for, or prior consent, to receive them. If you are
      sending any Chargeable Event for commercial purposes to any of Your
      end-users, You must abide by the mobile marketing practices of the
      end-user’s jurisdiction, including but not limited to, obtaining prior
      express written consent from those end-users, and give all end-users the
      right to opt out of receiving any further Chargeable Events sent by You
      for commercial purposes (and You shall promptly process any end-user’s
      election to opt out);
    </p>
    <p class="terms-desc-small">
      b.Use the Services or permit the Services to be used to convey Chargeable
      Events to any end-user, with a frequency and in numbers which are
      excessive in Our reasonable opinion;
    </p>
    <p class="terms-desc-small">
      c.Use the Services or permit the Services to be used for any improper,
      fraudulent, immoral or unlawful purpose;
    </p>
    <p class="terms-desc-small">
      d.Use the Services or permit the Services to be used for the transmission
      of any material which is of a defamatory, offensive, abusive, obscene or
      menacing character or nature;
    </p>
    <p class="terms-desc-small">
      e.Use the Services or permit the Services to be used in a manner that
      infringes the intellectual property rights or any other proprietary rights
      of any third party; or
    </p>
    <p class="terms-desc-small">
      f.Use the Services or permit the Services to be used in a manner that may
      injure or damage any person or property or cause the quality of the
      Services to be impaired.
    </p>
    <p class="terms-desc">
      2.You will at all times during the continuation of the Agreement:
    </p>
    <p class="terms-desc-small">
      a.Send only Chargeable Events that comply with all applicable laws,
      regulations and Codes and that contain nothing which is likely to cause
      offense;
    </p>
    <p class="terms-desc-small">
      b.Comply with all reasonable directions and instructions issued by Us from
      time to time in relation to the Services;
    </p>
    <p class="terms-desc-small">
      c.Comply with and observe at all times all applicable laws, regulations
      and Codes and any directions, recommendations and decisions of any
      Regulator; and
    </p>
    <p class="terms-desc-small">
      d.Not act in any manner likely to bring Us, the Service, the Free Service
      or any Network Operator into disrepute.
    </p>
    <p class="terms-desc">
      3.You will, upon request, provide Us or any Network Operator or Regulator
      with any information relating to Your use of the Services that the
      requesting party reasonably requires. You are responsible for ensuring
      that any information relating to Your end-users, including (but not
      limited to) Your end-user Data, is accurate and complete.
    </p>
    <p class="terms-desc">
      4.You will not state or imply any approval by Us of any particular
      Chargeable Event that You send using the Services or refer to Us in any
      way without Our prior written approval.
    </p>
    <p class="terms-desc">
      5.Where requested by Us, You will promptly provide Us with a
      representative Forecast of Your Service and/or Free Service needs for the
      requested period, including (but not limited to) all reasonable details
      required for Us to plan network capacity requirements.
    </p>
    <p class="terms-desc">
      6.We may, at Our sole discretion cease to convey, and You will promptly
      cease to transmit at Our request, any Chargeable Event.
    </p>
    <p class="terms-desc">
      7.You warrant that You are the sole owner or licensor of all rights in
      Your End-User Data or You have obtained all necessary rights, licenses and
      consents from all relevant third parties to enable You, Us and Our
      sub-contractors to use the End-User Data for the purposes of the
      Agreement.
    </p>
    <p class="terms-desc">
      YONI-TECH shall have the right to immediately stop your using the account,
      and has the right to claim damages for infringements, if any one of the
      above situations occurs.
    </p>
    <h2 class="terms-list-hdng">
      11. Disclaimers, Limitations of Liability and Indemnification
    </h2>
    <p class="terms-desc">
      1.TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, YONI-TECH SHALL NOT
      BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE
      DAMAGES, OR ANY LOSS OF PROFITS OR REVENUES, WHETHER INCURRED DIRECTLY OR
      INDIRECTLY, OR ANY LOSS OF DATA, USE, GOOD-WILL, OR OTHER INTANGIBLE
      LOSSES, RESULTING FROM (i) YOUR USE OR THE INABILITY TO USE THE SERVICES;
      (ii) THE COST OF PROCUREMENT OF SUBSTITUTE GOODS AND SERVICES RESULTING
      FROM ANY GOODS, DATA, INFORMATION, CONTENT AND/OR ANY OTHER SERVICES
      OBTAINED THROUGH THE SITE; (iii) THE UNAUTHORIZED ACCESS TO, OR ALTERATION
      OF, YOUR REGISTRATION DATA AND/OR VERIFIED PROFILE; AND (iv) ANY OTHER
      MATTER RELATING TO THE WEBSITE AND/OR THE SERVICES OFFERED ON THE WEBSITE.
    </p>
    <p class="terms-desc">
      2.THE LIMITATIONS OF THIS SUBSECTION SHALL APPLY TO ANY THEORY OF
      LIABILITY, WHETHER BASED ON WARRANTY, CONTRACT, STATUTE, TORT (INCLUDING
      NEGLIGENCE) OR OTHERWISE, AND WHETHER OR NOT THE YONI-TECH ENTITIES HAVE
      BEEN INFORMED OF THE POSSIBILITY OF ANY SUCH DAMAGE, AND EVEN IF A REMEDY
      SET FORTH HEREIN IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE.
    </p>
    <p class="terms-desc">
      3.You agree to indemnify and hold YONI-TECH, its officers, directors,
      shareholders, predecessors, successors in interest, employees, agents,
      subsidiaries and affiliates, harmless from any demands, loss, liability,
      claims or expenses (including attorneys’ fees), made against YONI-TECH by
      any third party due to, arising out of or in connection with your use of
      the website and/or the Services.
    </p>
    <p class="terms-desc">
      4.We will not be in any way liable for the content of any Chargeable
      Events sent or transmitted using the Service and the full responsibility
      for their content shall rest on you. You must observe all relevant
      legislation and regulations applicable in your jurisdiction and in the
      jurisdiction of all persons with whom you communicate directly when using
      the Service. By using the Service you also agree to be bound by the Rules
      of Use.
    </p>
    <p class="terms-desc">
      5.Subject to any express provision to the contrary in these Terms, We will
      not in any circumstances be liable to You (as either a Customer or a
      Non-Paying Customer) in contract, tort, negligence or otherwise for any
      economic loss (including, but not limited to, any loss or profits,
      business, contracts, revenue, turnover or anticipated savings) or for any
      indirect or consequential losses, whether or not they were foreseen or
      foreseeable.
    </p>
    <p class="terms-desc">
      6.Each party acknowledges that neither You (as either a Customer or a
      Non-Paying Customer), nor We, have entered into the Agreement on the basis
      of or in reliance upon any representation (save for any representation
      made fraudulently), warranty or other term except as otherwise expressly
      provided in the Terms and, as such, all conditions, warranties and other
      terms implied by statute, common law or otherwise are hereby excluded to
      the greatest extent permitted by law.
    </p>
    <p class="terms-desc">
      7.We shall at all times in respect of the subject matter of these Terms
      comply with all applicable laws, regulations and rules having equivalent
      effect.
    </p>
    <p class="terms-desc">
      7.We shall at all times in respect of the subject matter of these Terms
      comply with all applicable laws, regulations and rules having equivalent
      effect.
    </p>
    <p class="terms-desc">
      8.You shall be responsible for explaining and answering to any complaints
      that We receive from any relevant regulatory body resulting from your use
      of the Service and/or Free Service. We will forward any complaints to you
      as soon as it is reasonably possible. You must follow the applicable
      complaint procedures and respond to each complaint within the timeframes
      specified by the relevant regulatory body and must forward a copy of your
      response to Us immediately. You will be liable for any fines and/or
      penalties imposed by any regulatory body against You or Us or any of our
      associated companies, due to Your contravention of these Terms.
    </p>
    <h2 class="terms-list-hdng">12. Term and Termination</h2>
    <p class="terms-desc">1.Termination by YONI-TECH.</p>
    <p class="terms-desc-small">
      a.To the extent permitted under applicable law, YONI-TECH may, at the sole
      discretion, terminate these Terms, or terminate your access to the
      Services or any aspect of the Services immediately upon written notice to
      you if:
    </p>
    <p class="terms-desc-small-pl">
      a.You violate any provision of these Terms;
    </p>
    <p class="terms-desc-small-pl">
      b.YONI-TECH reasonably believes that You have violated any applicable
      laws, or engaged in any fraudulent or deceptive activity, in connection
      with Your use of the Services;
    </p>
    <p class="terms-desc-small-pl">
      c.You enter into liquidation, administrative receivership, bankruptcy or
      are unable to pay your debts as they fall due.
    </p>
    <p class="terms-desc-small">
      b.YONI-TECH reasonably believes that You have engaged in any fraudulent or
      deceptive activity including but not limited to credit card fraud, We may
      freeze your account.
    </p>
    <p class="terms-desc-small">
      c.If You believe there is relevant evidence that your use is legitimate,
      you may send an email to 【service@yoni-tech.com】 to complain.
    </p>
    <p class="terms-desc">
      2.Unless otherwise agreed with Your account manager, and subject to
      earlier termination in accordance with these Terms and Conditions, the
      Term is, for an initial minimum, a 11-month period (the “Initial Term”).
      The Initial Term will start on the Start Date (the day when you start
      using the Service or the Free Service) and expire on the End Date (the day
      corresponding the date in the month in which the initial service term
      expires). Upon expiration of the Initial Term, the Agreement will continue
      automatically unless and until either party gives the other 30 days’
      written notice or either You or We otherwise terminate the Agreement in
      accordance with these Terms and Conditions.
    </p>
    <p class="terms-desc">
      3.If at any time You wish to terminate the Agreement, You must either:
    </p>
    <p class="terms-desc-small">
      a.Give Us written notice to that effect within a 7-working day period from
      and including the Start Date, in order for such notice to take effect
      immediately; or
    </p>
    <p class="terms-desc-small">
      b.Give Us no less than 30 days’ written notice, such notice will take
      effect no sooner than the End Date (subject to any variation thereof). If
      You wish to terminate the Agreement with effect from any date preceding
      such End Date and are unable to do so for cause or any other reason under
      these Terms and Conditions, if you are a Customer under the Agreement then
      You must pay Us the Customer Charges due for each month (or part thereof)
      remaining of the minimum 11-month period post-termination. Such Customer
      Charges will be calculated on the basis of the monthly Customer Charges as
      set out in the Signature Page (as varied if applicable), and any
      prepayment of Customer Charges that You have made to Us will not be
      credited or refunded (“No refund, exchange only”). The latter shall not
      prevent any refund to be made according to the applicable customer
      protection laws.
    </p>
    <p class="terms-desc">
      4.Either party may also terminate this Agreement with immediate effect by
      notice to the other party if:
    </p>

    <p class="terms-desc-small">
      a.The other party becomes insolvent, makes any arrangement with or for the
      benefit of its creditors, goes into compulsory or voluntary liquidation,
      has a receiver, administrative receiver, liquidator or other similar
      official appointed over its assets, is subject to an administration or
      similar order or ceases trading;
    </p>
    <p class="terms-desc-small">
      b.The other party commits a material breach of the Agreement and (where
      such breach is capable of remedy) fails to remedy the breach within 14
      days of a written notice from the party not in breach requiring its
      remedy; or
    </p>
    <p class="terms-desc-small">
      c.Any license required for Us to operate the Services is revoked,
      terminated or modified or, in the case of new license requirements being
      imposed, the applicable license:
    </p>
    <p class="terms-desc-small-pl">a.Is not granted to Us; or</p>
    <p class="terms-desc-small-pl">
      b.Is granted to Us but in such a way as to prevent Us from continuing to
      make the Services available or a Network Operator from enabling Us to make
      the Services available.
    </p>
    <p class="terms-desc-small">
      d.We may terminate the Agreement immediately upon notice in the event that
      any relevant legislation or regulation is implemented or modified with the
      effect that it is no longer commercially viable or possible for Us to make
      the Services available.
    </p>
    <p class="terms-desc-small">
      e.If you are using the Free Service under this Agreement You acknowledge
      that We reserve the right to, at Our absolute discretion, stop providing
      the Free Service to You on either a temporary or a permanent basis for any
      reason whatsoever without any prior notice.
    </p>
    <p class="terms-desc-small">
      f.Termination of the Agreement for any reason does not affect any rights
      that have accrued to either party under the Agreement up to the date of
      its termination and those terms and conditions of the Agreement that are
      by their nature capable of surviving termination will continue in full
      force and effect following such termination.
    </p>
    <p class="terms-desc-small">g.On termination of the Agreement:</p>

    <p class="terms-desc-small-pl">
      a.You will immediately cease to use the Services; and
    </p>
    <p class="terms-desc-small-pl">
      b.All amounts then owed to Us, under or in connection with the Agreement,
      will become immediately due and payable.
    </p>
    <p class="terms-desc-small-pl">
      c.You will forfeit any unused credit on your account, except for payments
      received by us within seven (7) days prior to termination.
    </p>
    <p class="terms-desc-small-pl">
      d.All licenses and rights granted under these Terms will terminate
      immediately.
    </p>
    <h2 class="terms-list-hdng">13. Force Majeure</h2>
    <p class="terms-desc-small">
      Neither party will be liable for any delay in the performance of or any
      failure to perform any of its obligations under this Agreement that is
      caused by any event which is beyond its reasonable control, including, but
      not limited to, the failure, malfunction or unavailability of necessary
      telecommunications, data communications and/or computer services, power
      supply failures or shortages, acts or omissions of third parties
      (including, but not limited to, Network Operators), acts of government or
      Regulators or telecommunications network congestion.
    </p>
    <h2 class="terms-list-hdng">14. Assignment</h2>
    <p class="terms-desc-small">
      Neither party will assign, transfer or sub-contract either in whole or in
      part any of its rights or obligations under the Agreement without the
      other party’s prior written consent (not to be unreasonably withheld or
      delayed), provided that We shall be entitled without Your prior written
      consent to assign, transfer or sub-contract in whole or in part any of its
      rights or obligations under the Agreement to any affiliated company.
    </p>
    <h2 class="terms-list-hdng">15. Intellectual Property</h2>
    <p class="terms-desc-small">
      1.All content, trademarks and data on our website, including software,
      databases, text, graphics, icons, hyperlinks, private information, and
      designs are the property of or licensed to Us, and as such, are protected
      from infringement by domestic and international legislation and treaties.
      Subject to the rights afforded to You in these Terms, all other
      intellectual property rights on this website are expressly reserved.
    </p>
    <p class="terms-desc-small">
      2.We may grant You an individual, personal, non-exclusive and
      non-transferable license (“the License”) to use our proprietary software
      or application service, in object code form only, and only in accordance
      with the applicable Service Specific terms and other documentation, if
      any, and only in conjunction with the relevant services. You may not
      reverse engineer, decompile, disassemble or otherwise attempt to establish
      the source code or underlying ideas or algorithms of our software; modify,
      translate, or create derivative works based on the software or
      application; copy, rent, lease, distribute, assign, or otherwise transfer
      rights to the software or application; or remove any proprietary notices
      or labels with regard to our services. We retain ownership of all
      propriety applications, software, intellectual property and any portions
      or copies thereof, and all rights in it. You will notify Us of any
      suspected infringement of Our intellectual property of which You become
      aware and will take all reasonable action as We may direct in relation to
      that suspected infringement where such is directly and specifically
      related to the services we provide you.
    </p>
    <p class="terms-desc-small">
      3.These Licenses terminate when Our contract with you ends and you must
      destroy and stop using all of our software and applications in your
      possession. The software is provided and applications are offered “as is”
      and subject to the service warranty disclaimers and limitations of
      liability found elsewhere in these Terms. It is your responsibility to
      test the services before entering into this contract.
    </p>
    <p class="terms-desc-small">
      4.Content from Our website may not be used or exploited for any commercial
      and non-private purposes without Our prior written consent.
    </p>
    <h2 class="terms-list-hdng">16. Severability</h2>
    <p class="terms-desc-small">
      If any term or other provision of this Agreement is determined to be
      invalid, illegal or incapable of being enforced by any rule or law, or
      public policy, all other conditions and provisions of this Agreement shall
      nevertheless remain in full force and effect.
    </p>
    <h2 class="terms-list-hdng">17. Governing Law & Dispute Resolution</h2>
    <p class="terms-desc-small">
      These Terms shall be governed by and construed in accordance with laws of
      the People’s Republic of China and Any dispute arising under or concerning
      this Agreement may be litigated only in the Shang International Economic
      and Trade Arbitration Commission and the prevailing Party shall be
      entitled to its reasonable attorneys’ fees, expenses and costs incurred in
      connection therewith in addition to such other relief as may be granted.
    </p>
  </div>
</template>
<script>
import word from "./../assets/json/language.json";

export default {
  name: "Service",
  components: {},
  created() {
    this.language = localStorage.getItem("language");
  },
  mounted() {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // 可选的平滑滚动效果
    });
  },
  data() {
    return {
      language: "",
      word: word,
    };
  },
};
</script>


<style scoped lang="scss">
.product-title {
  width: 100%;
  height: 300px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-bottom: 100px;
  background-color: #6c63ff;
  img {
    display: block;
    height: 300px;
    text-align: center;
  }
  .banner-text {
    position: absolute;
    left: 30%;
    top: 40%;
    font-size: 46px;
    color: #fff;
  }
}
.product-info {
  .product-item {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    img {
      width: 400px;
      flex-shrink: 0;
    }
    > div {
      width: 380px;
      line-height: 1.8;
      padding: 0 50px;
      color: #888;
      .product-subtitle {
        font-size: 30px;
        margin-bottom: 10px;
        color: #353535;
      }
    }
  }
}

.content {
  padding: 20px;

  .terms-hdng {
    color: #000;
    font-size: 32px;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 12px;
  }

  .terms-desc {
    font-size: 16px;
    color: #000;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.32px;
    margin-bottom: 10px;
    margin-left: 20px;
    text-align: justify;
  }

  .terms-list-hdng {
    color: #000;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.2;
    letter-spacing: 0.36px;
    margin: 30px 0 10px;
  }
  .terms-list-hdng-small {
    color: #000;
    font-size: 18px;
    line-height: 1.2;
    letter-spacing: 0.36px;
    margin: 30px 0 10px;
  }
  .terms-list-hdng-h3 {
    color: #000;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.2;
    letter-spacing: 0.36px;
    margin: 30px 20px 10px 20px;
  }

  .terms-desc-small {
    font-size: 16px;
    color: #000;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.32px;
    margin-bottom: 10px;
    margin-left: 40px;
    text-align: justify;
  }

  .terms-desc-small-pl {
    font-size: 16px;
    color: #000;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.32px;
    margin-bottom: 10px;
    margin-left: 60px;
    text-align: justify;
  }
}
</style>